@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;500;600;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,700;1,100;1,200;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'GT Super Text';
  src: url('../public/fonts/GT-Super/GT-Super-Text-Regular-Trial.otf');
}

body {
  @apply bg-gray-50;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .form-check {
    input[type='checkbox'] {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 1em;
      height: 1em;
      border: 0.05em solid #bcf0da;
      border-radius: 4px;
      transform: translate(-0.2em, 0.25em);
    }
    input[type='checkbox']::before {
      display: block;
      content: '';
      background-image: url('images/checked-icon.svg');
      width: 10px;
      height: 16px;
      margin-left: 1px;
      transform: scale(0);
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }

    input[type='checkbox']:checked {
      background: #0e9f6e;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.block-f {
  display: block !important;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 1em;
  padding: 0 20px 0 10px;
}

.step-wrapper {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.transition-out {
  transform: translateX(-20px);
  opacity: 0;
}
