.spotItem {
  border-radius: 8px;
  border: 2px solid #d1d5db;
  background-color: #fff;
  color: #111827;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;

  &.selected {
    border-radius: 100px;
    background-color: #309c42;
    border-color: #309c42;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  &:disabled {
    border: 1px dashed #333;
    font-style: italic;
  }
}
